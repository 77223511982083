import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from './translation/en.json';
import translationVI from './translation/vi.json';
import translationCN from './translation/cn.json';

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: {
      translations: translationEN,
    },
    vn: {
      translations: translationVI,
    },
    cn: { translations: translationCN },
  },
  fallbackLng: 'vn',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  interpolation: {
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
});

export default i18n;
