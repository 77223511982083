import React from 'react';
import '../App.css';
import Const from '../Const';
import { getQue, gioHoangDao, HourToCanChi, tinhHaDoQuai, tinhHaDoVan } from '../convert';
import PhiTinh from '../PhiTinh';
import TableQue from '../TableQue';

class HourStar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderItemColor(item, color) {
    return (
      <td
        width="8%"
        style={{
          backgroundColor: color,
          color: color !== '#fff' ? 'white' : '#434343',
          fontWeight: 'bold',
          fontSize: '70%',
        }}
      >
        {item}
      </td>
    );
  }
  totXauConGiap() {
    const chiNgay = this.props.chiDay;
    const chi = Const.Chi;
    if (!chiNgay) {
      return [];
    }
    switch (chiNgay) {
      case Const.Chi.Ty.name:
        return [chi.Than, chi.Ty, chi.Thin, chi.Suu];
      case Const.Chi.Suu.name:
        return [chi.Ty, chi.Ti, chi.Dau, chi.Suu];
      case Const.Chi.Dan.name:
        return [chi.Dan, chi.Ngo, chi.Tuat, chi.Hoi];
      case Const.Chi.Mao.name:
        return [chi.Hoi, chi.Mao, chi.Mui, chi.Tuat];
      case Const.Chi.Thin.name:
        return [chi.Than, chi.Ty, chi.Dau];
      case Const.Chi.Ti.name:
        return [chi.Ti, chi.Dau, chi.Suu];
      case Const.Chi.Ngo.name:
        return [chi.Dan, chi.Tuat, chi.Mui];
      case Const.Chi.Mui.name:
        return [chi.Hoi, chi.Mao, chi.Mui, chi.Ngo];
      case Const.Chi.Than.name:
        return [chi.Than, chi.Ty, chi.Thin];
      case Const.Chi.Dau.name:
        return [chi.Ti, chi.Dau, chi.Suu, chi.Thin];
      case Const.Chi.Tuat.name:
        return [chi.Dan, chi.Ngo, chi.Tuat, chi.Mao];
      case Const.Chi.Hoi.name:
        return [chi.Mao, chi.Mui, chi.Dan];
      default:
        break;
    }
  }
  inforOneHour(index, header, canDefault, chiDefault, picture, pictureRed) {
    // lấy hour tính phitinh cho mỗi giờ
    const hour = Number(header.split(':')[0]);

    // lấy thông tin can chi cho giờ và lấy để tính luôn hà đồ
    const getCanChiHour = HourToCanChi(this.props.canDay, hour)?.name.split(' ');
    //thông tin cần có để lấy hà Đồ
    const canHour = getCanChiHour ? getCanChiHour[0] : canDefault;
    const chiHour = getCanChiHour ? getCanChiHour[1] : chiDefault;
    let getChiDayObj = Const.ChiArr.find((v) => v.name === this.props.chiDay);
    let getCanDayObj = Const.CanArr.find((v) => v.name === this.props.canDay);
    const canDay = getCanDayObj ? getCanDayObj : canDefault;
    const chiDay = getChiDayObj ? getChiDayObj : chiDefault;

    const getHaDoQuai = tinhHaDoQuai(canDay.name, chiDay.name, canHour, chiHour);
    const getHaDoVan = tinhHaDoVan(canDay.name, chiDay.name, canHour, chiHour);
    // tính giờ hoàng đạo
    let chiHourHD = Const.ChiArr.find((v) => v.name === chiHour);
    const temp = gioHoangDao(chiDay?.code, chiHourHD.code + 1);
    const hoangDao = temp ? temp : '';

    const totXauArray = this.totXauConGiap();

    let isTotXau = false;
    totXauArray.forEach((e) => {
      if (e.code === index) {
        isTotXau = true;
      }
    });

    //tính quẻ
    const que = getQue(canHour, chiHour);

    let sizeOfImage = '50px';
    let conGiap = '';
    //tý
    if (index === 0) {
      sizeOfImage = '40px';
      conGiap = Const.Chi.Ty.name;
    }
    //sửu
    if (index === 1) {
      sizeOfImage = '50px';
      conGiap = Const.Chi.Suu.name;
    }
    if (index === 2) {
      sizeOfImage = '75px';
      conGiap = Const.Chi.Dan.name;
    }
    if (index === 3) {
      sizeOfImage = '45px';
      conGiap = Const.Chi.Mao.name;
    }
    if (index === 4) {
      sizeOfImage = '70px';
      conGiap = Const.Chi.Thin.name;
    }
    if (index === 5) {
      sizeOfImage = '60px';
      conGiap = Const.Chi.Ti.name;
    }
    if (index === 6) {
      sizeOfImage = '70px';
      conGiap = Const.Chi.Ngo.name;
    }
    if (index === 7) {
      sizeOfImage = '60px';
      conGiap = Const.Chi.Mui.name;
    }
    if (index === 8) {
      sizeOfImage = '50px';
      conGiap = Const.Chi.Than.name;
    }
    if (index === 9) {
      sizeOfImage = '50px';
      conGiap = Const.Chi.Dau.name;
    }
    if (index === 10) {
      sizeOfImage = '70px';
      conGiap = Const.Chi.Tuat.name;
    }
    if (index === 11) {
      sizeOfImage = '70px';
      conGiap = Const.Chi.Hoi.name;
    }

    return (
      <table>
        <tbody>
          <tr id="textColor" style={{ color: '#434343' }}>
            <td>{header}</td>
          </tr>
          <tr style={{ backgroundColor: '#1667AF', color: '#fff' }}>
            <td>{canHour}</td>
          </tr>
          <tr style={{ backgroundColor: '#1667AF', color: '#fff' }}>
            <td>{chiHour}</td>
          </tr>
          <tr>
            <td>
              <TableQue data={que} isSmall />
            </td>
          </tr>
          <tr
            style={{
              backgroundColor: '#fff',
              color: '#434343',
              fontSize: '80%',
              lineHeight: '10px',
              whiteSpace: 'pre-wrap',
              fontWeight: 'bold',
            }}
          >
            <td id="borderTable">{que.tenque}</td>
          </tr>

          <tr
            height="24px"
            style={{
              backgroundColor: getHaDoQuai ? '#F05828' : '#fff',
              color: '#fff',
              fontSize: '80%',
            }}
          >
            <td>{getHaDoQuai ? getHaDoQuai : '.'}</td>
          </tr>
          <tr
            height="24px"
            style={{
              backgroundColor: getHaDoVan !== ' ' ? '#F05828' : '#fff',
              color: '#fff',
              fontSize: '80%',
            }}
          >
            <td>{getHaDoVan ? getHaDoVan : ' '}</td>
          </tr>
          <tr
            className="to-hide-print"
            height="20px"
            style={{
              // backgroundColor: '#F05828',
              color: '#fff',
              fontSize: '80%',
            }}
          ></tr>
          <tr
            style={{
              backgroundColor: '#fff',
              color: hoangDao.isHoangDao ? 'red' : '#434343',

              fontSize: '70%',
              fontWeight: 'bold',
            }}
          >
            <td>{hoangDao.Name}</td>
          </tr>

          <tr
            className="to-hide-print"
            height="20px"
            style={{
              backgroundColor: '#fff',
            }}
          ></tr>
          <tr>
            <td>
              <PhiTinh isSmall data={this.props.dataPhiTinh} chiHour={hour} chiDay={this.props.chiDay} isSauDongChi={this.props.isSauDongChi} />
            </td>
          </tr>
          <tr height="5px"></tr>
          <tr>
            <th height="110px" className="to-hide-print">
              {isTotXau ? (
                <img className="containPicture" src={pictureRed} style={{ width: sizeOfImage, height: sizeOfImage }} alt="logo" />
              ) : (
                <img className="containPicture" src={picture} style={{ width: sizeOfImage, height: sizeOfImage }} alt="logo" />
              )}
            </th>
          </tr>
          <tr>
            <td>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div
                        style={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: isTotXau ? 'red' : '#434343',
                          // position: 'absolute',
                        }}
                      ></div>
                    </td>
                    <td width="10px"></td>
                    <td>
                      <p>{isTotXau ? 'Tốt' : 'Xấu'}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ color: '#434343' }}> {`Tuổi ${conGiap}`}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  render() {
    const data = this.props.data;

    return (
      <table className="inforEachHour">
        <tbody>
          <tr style={{ whiteSpace: 'nowrap', backgroundColor: '#1667AF' }}>
            <td
              colSpan="12"
              style={{
                textAlign: 'left',
                padding: '5px',
                color: '#fff',
                fontWeight: 'bold',
              }}
            >
              Sao Theo Giờ
            </td>
          </tr>
          <tr>
            {data.map((v, index) => {
              return (
                <td key={`inforEachHour-${index}`} className="inforEachHour">
                  {this.inforOneHour(
                    index,
                    v.header,
                    v.canChi,
                    v.diaChi,
                    // v.que,
                    v.picture,
                    v.pictureRed,
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }
}

export default HourStar;
