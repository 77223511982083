import 'firebase/firestore';
import 'firebase/storage';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import Home from './Home';

export default function BasicExample() {
  return (
    <Router>
      <Header></Header>
      <Home />
      <Footer></Footer>
    </Router>
  );
}
